export const constants = {
  /* Cookies */

  ACCESS_TOKEN: "access-token",
  REFRESH_TOKEN: "refresh-token",

  /* Local Storage */

  localstorage: {
    KNOWN_VERSION: "rdm-known-version",
  },

  /* Session Storage */

  sessionstorage: {
    USER_MANAGEMENT_PAGE: "rdm-user-mgmt-page",
    USER_MANAGEMENT_SELECTED_ROLE: "rdm-user-mgmt-select-role",
    USER_MANAGEMENT_SELECTED_POS: "rdm-user-mgmt-select-pos",
  },

  /* Roles */
  definitionRoles: {
    RESELLER_PORTAL_SUPER_ADMIN: "RESELLER_PORTAL_SUPER_ADMIN",
    RESELLER_PORTAL_LOCATION_SUPER_ADMIN: "RESELLER_PORTAL_LOCATION_SUPER_ADMIN",
    RESELLER_PORTAL_LOCATION_ADMIN: "RESELLER_PORTAL_LOCATION_ADMIN",
    RESELLER_PORTAL_MANAGER: "RESELLER_PORTAL_MANAGER",
    RESELLER_PORTAL_AGENT: "RESELLER_PORTAL_AGENT",
    RESELLER_PORTAL_REPORTS: "RESELLER_PORTAL_REPORTS",
  },

  /* Availability Types */
  availabilityTypes: {
    seating: "Seating",
    best_available: "Best available",
    attraction: "Attraction",
  } as { [index: string]: any },

  roles: {
    SUPPLIER_BUSINESS_STAFF: "SUPPLIER_BUSINESS_STAFF",
    SUPPLIER_REDEMPTION_STAFF: "SUPPLIER_REDEMPTION_STAFF",
    SUPPLIER_REPORTING_STAFF: "SUPPLIER_REPORTING_STAFF",
    SUPPLIER_REPORTING_ALL_BOOKING_STAFF: "SUPPLIER_REPORTING_ALL_BOOKING_STAFF",
    SUPPLIER_REPORTING_CUSTOMER_MANIFEST_STAFF: "SUPPLIER_REPORTING_CUSTOMER_MANIFEST_STAFF",
    SUPPLIER_REPORTING_REDEMPTIONS_STAFF: "SUPPLIER_REPORTING_REDEMPTIONS_STAFF",
    ORG_MGMT: "ORG_MGMT",
    REDEAM_TECHNICAL_STAFF: "REDEAM_TECHNICAL_STAFF",
    RESELLER_PORTAL_SUPER_ADMIN: "RESELLER_PORTAL_SUPER_ADMIN",
    RESELLER_PORTAL_LOCATION_ADMIN: "RESELLER_PORTAL_LOCATION_ADMIN",
    RESELLER_PORTAL_MANAGER: "RESELLER_PORTAL_MANAGER",
    RESELLER_PORTAL_AGENT: "RESELLER_PORTAL_AGENT",
    RESELLER_PORTAL_REPORTS: "RESELLER_PORTAL_REPORTS",
    RESELLER_PORTAL_LOCATION_SUPER_ADMIN: "RESELLER_PORTAL_LOCATION_SUPER_ADMIN",
  },

  /* Features */

  features: {
    ROUTING_USER_MAIN: "ROUTING_USER_MAIN",
    ROUTING_COMPANY_MAIN: "ROUTING_COMPANY_MAIN",
    ROUTING_PRODUCT_MAIN: "ROUTING_PRODUCT_MAIN",
    SIDEBAR_USER_ITEM: "SIDEBAR_USER_ITEM",
    SIDEBAR_SALES_ITEM: "SIDEBAR_SALES_ITEM",
    SIDEBAR_COMPANY_ITEM: "SIDEBAR_COMPANY_ITEM",
    SIDEBAR_PRODUCT_ITEM: "SIDEBAR_PRODUCT_ITEM",
    WELCOME_WIZARD: "WELCOME_WIZARD",
    ROUTING_BOOKING_MAIN: "ROUTING_BOOKING_MAIN",
    ROUTING_BOOKING_DETAILS: "ROUTING_BOOKING_DETAILS",
    ROUTING_REPORTS: "ROUTING_REPORTS",
    SIDEBAR_HELP_CENTER_FEATURE_ITEM: "SIDEBAR_HELP_CENTER_FEATURE_ITEM",
  },

  /* Event Listeners */

  events: {
    GUEST_TYPE_LOAD_COMPLETE: "guest-type-load-complete",
    INIT_LOAD_COMPLETE: "init-load-complete",
    OPTION_LOAD_COMPLETE: "option-load-complete",
    OPTION_CHOOSE: "option-choose",
    OPEN_FULLSCREEN_CALENDAR: "open-fullscreen-calendar",
    PRODUCT_LOAD_COMPLETE: "product-load-complete",
    REFRESH_PRODUCTS: "refresh-products",
    RESIZE: "resize",
    REDRAW_DEPARTURE_MAP: "redraw-departure-map",
    CREATE_PRODUCT: "create-product",
    CREATE_OPTION: "create-option",
    CREATE_TRAVELER_TYPE: "create-traveler-type",
  },

  /* Route Names */

  routes: {
    // public
    LOGIN: "login",
    NOT_FOUND: "not-found",
    FORGOT_PASSWORD: "forgot-password",
    RESET_PASSWORD: "reset-password",

    // public wip
    REGISTER: "register",
    ACCOUNT_REQUESTED: "accountRequested",
    AVAILABILITY: "availabilityCalendar",

    // private
    BASE: "base",
    HOME: "home",
    PROFILE: "user-profile",
    MILITARY: "military",

    // private, users
    USERS: "users",
    USER_DETAILS: "user-details",
    USER_DETAILS_VIEW: "user-details-view",
    USER_MANAGEMENT_ADD: "user-management-add",

    // private, bookings
    BOOKINGS: "bookings",
    BOOKING_DETAILS: "booking-detail-view",
    BOOKINGS_ADD: "add-bookings",
    ALL_BOOKINGS: "all-bookings-report",

    //pricing
    TICKET_TYPES: "ticket-types",
    PRICING_ADJUSTMENT: "pricing-adjustment",
    PRICING_ADJUSTMENT_LIST: "pricing-adjustment-list",
    PRICING_SCHEDULE: "pricing-schedule",
    PRICING_LISTING: "pricing-listing",

    // private, company
    COMPANY: "company-info",
    LOCATIONS: "company-location-listings",
    CONTACTS: "company-contacts",
    CONTACTS_ADD: "company-contacts-add",
    CONTACTS_EDIT: "company-contacts-edit",
    CONTACTS_VIEW: "company-contacts-view",
    COMPANY_EDIT: "company-edit-info",
    COMPANY_ADD: "company-add-info",
    LOCATION_DETAILS: "company-location-details",
    LOCATION_ADD: "company-location-add",
    LOCATION_EDIT: "company-location-edit",
    LOCATION_VIEW: "company-location-view",

    // private, products
    PRODUCT_LIST: "products",
    OPTION_LIST: "option-list-summary",
    TRAVELER_LIST: "traveler-type-summary",
    PRODUCT_DETAILS: "product-details",
    PRODUCT_OPTIONS: "product-options",
    PRODUCT_EDIT: "product-edit",
    PRODUCT_CREATE: "create-product",
    OPTION_DETAILS: "option-details",
    OPTION_TIME_CAPACITY_ADD: "option-time-capacity-add",
    OPTION_TIME_CAPACITY_EDIT: "option-time-capacity-edit",
    OPTION_TIME_CAPACITY_VIEW: "option-time-capacity",
    PRODUCT_TIME_CAPACITY_ADD: "product-time-capacity-add",
    PRODUCT_TIME_CAPACITY_EDIT: "product-time-capacity-edit",
    PRODUCT_TIME_CAPACITY_VIEW: "product-time-capacity",

    // private, inventory
    PRICING_LIST: "pricing",
    INVENTORY_AVAILABILITY: "inventory-availability",
    INVENTORY_MANAGEMENT: "inventory-management",
    AVAILABILITY_STATUS: "availability-status",
    PRICING_VIEW: "pricing-view",
    PRICING_DETAILS: "pricing-details",
    PRICING_EDIT: "pricing-edit",
    PRICING_CREATE: "pricing-create",
    AVAILABILITY_DETAILS: "inventory-availability-details",
    AVAILABILITY_EDIT: "inventory-availability-edit",
    AVAILABILITY_CREATE: "inventory-availability-create",
    TAXES_AND_FEES: "taxes-and-fees",
    TAXES_AND_FEES_VIEW: "taxes-and-fees-view",
    TAXES_AND_FEES_CREATE: "taxes-and-fees-create",
    TAXES_AND_FEES_EDIT: "taxes-and-fees-edit",
    FEEDBACK: "feedback",
  },

  /* Titles */
  titles: {
    ADMIN: "Admin",
    ALL_BOOKINGS: "All Bookings",
    COMPANY: "Company",
    COMPANY_ABOUT: "About",
    CONTACT: "Contact",
    CONTACTS: "Contacts",
    CONTACTS_ADD: "Add Contact",
    CONTACTS_EDIT: "Edit Contact",
    CUSTOMER_MANIFEST: "Customer Manifest",
    HOME: "Home",
    INVENTORY: "Inventory",
    LOCATION: "Location",
    LOCATION_ADD: "Add Location",
    LOCATION_EDIT: "Edit Location",
    LOCATION_VIEW: "Location",
    LOCATIONS: "Locations",
    MILITARY: "Military",
    OPTION: "Option",
    PRICING: "Pricing",
    PRICING_CREATE: "Add Pricing",
    PRICING_EDIT: "Edit Pricing",
    PRICING_INVENTORY: "Pricing & Inventory",
    PRICING_VIEW: "View Pricing",
    PRICING_ADJUSTMENT: "Pricing Adjustment",
    PRICING_SCHEDULE: "Pricing Schedule",
    PRODUCT: "Product",
    PRODUCTS: "Products",
    PRODUCT_TIME_CAPACITY_ADD: "Add Time & Capacity",
    PRODUCT_TIME_CAPACITY_EDIT: "Edit Time & Capacity",
    PRODUCT_TIME_CAPACITY_VIEW: "Time & Capacity",
    RATE_CARD: "Rate Card",
    RATE_CARDS: "Rate Cards",
    RATE_CARDS_EDIT: "Edit Rate Cards",
    REDEMPTION: "Redemption",
    REDEMPTIONS: "Redemptions",
    REPORT: "Report",
    REPORTS: "Reports",
    SETTINGS: "Settings",
    TICKET_TYPES: "Ticket Types",
    TRAVELER_TYPE: "Traveler Type",
    TRAVELER_TYPES: "Traveler Types",
    USER: "User",
    USERS: "User Management",
    FEEDBACK: "Feedback",
    BOOKINGS: "Bookings",
    BOOKING_DETAILS: "Booking Details",
    BOOKINGS_ADD: "Bookings Add",
    AllBookings: "All Bookings",
    ArrivalsReport: "Upcoming Arrivals",
  },

  /* Endpoints and URL paths */
  endpoints: {
    COMPANIES: "companies",
    SUPPLIERS: "suppliers",
    PRODUCTS: "products",
    ADDRESSES: "addresses",
    AUDIT: "audit",
  },

  messages: {
    AVAILABILITY_STATUS_GETTING_STARTED_NO_PRICE_INVENTORY: "Add pricing and inventory to start managing availability",
    AVAILABILITY_STATUS_GETTING_STARTED_NO_PRICE: "Add pricing to start managing availability",
    AVAILABILITY_STATUS_GETTING_STARTED_NO_INVENTORY: "Add inventory to start managing availability",
    RATE_CARD_GETTING_STARTED_NO_SEARCH_RESULTS: "Start a new search or add a new rate card to continue viewing",
    RATE_CARD_GETTING_STARTED_NO_RATE_CARD: "Add pricing to begin managing rate cards for channels",
    RATE_CARD_GETTING_STARTED_NO_PRICING_CARD: "Add rate cards to provide custom pricing and discounts for channels",
  },

  /* Fields */
  fields: {
    PRODUCT_DESCRIPTION: "product-description",
    PRODUCT_NAME: "product-name",
  },

  travlerTypes: {
    ADULT: "ADULT",
    CHILD: "CHILD",
    INFANT: "INFANT",
    SENIOR: "SENIOR",
    YOUTH: "YOUTH",
    STUDENT: "STUDENT",
    ANY: "ANY",
  },

  travlerDisplayNames: {
    ADULT: "ADULTS",
    CHILD: "CHILDREN",
    INFANT: "INFANTS",
    SENIOR: "SENIORS",
    YOUTH: "YOUTHS",
    STUDENT: "STUDENTS",
    ANY: "ANY",
  },

  parks: [
    {
      code: "magic-kingdom",
      name: "Magic Kingdom® Park",
    },
    {
      code: "animal-kingdom",
      name: "Disney's Animal Kingdom® Theme Park",
    },
    {
      code: "hollywood-studios",
      name: "Disney's Hollywood Studios®",
    },
    {
      code: "epcot",
      name: "EPCOT®",
    },
  ],
  reservationStatus: {
    none: "none",
    skipped: "skipped",
    success: "success",
    failure: "failure",
    partialFailure: "partialFailure",
    error: "error",
  },

  // TODO

  /* User Management actions */
  ACTIVATE_USER: "activateUser",
  ROLE_ADD: "addRole",
  ROLE_REMOVE: "removeRole",
  REMOVE_USER: "removeUser",

  bookingStatus: {
    Open: "Open",
    Pending: "Pending",
    Cancelled: "Cancelled",
    Failed: "Failed",
  },

  /* max length validations */
  RESELLER_REF_MAX_LENGTH: 50,
  /** max number of search results that can be returned in a single query */
  MAX_BOOKING_SEARCH_RESULTS: 500,
  /** the max length of the Disney bulk ticket instructions*/
  DISNEY_BULK_TICKET_INSTRUCTIONS_MAX_LENGTH: 1000,
  /* default currency is USD */
  DEFAULT_CURRENCY: "USD",
} as const;

export type Role = keyof typeof constants.roles;
