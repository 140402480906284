import axios, { AxiosInstance } from "axios";
import { forkJoin, from, Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { config } from "@/utils/config";
import { AxiosErrorWithResponse, intercept } from "@/api/AxiosInterceptors";
import { handleErrs } from "@/api/ErrHandlers";
import { PriceScheduleRS } from "./RateModels";
import _ from "lodash";
import { RateErrHandlers } from "./ErrHandlers";
import { FromSmallestUnit } from "@/utils/currency";
import { extractTimezoneFromRates, getDiscountGroupName, getMarketRegionName, sortAlpha, calculateValidityRange } from "@/utils/helpers";
import availabilityClient from "@/api/booking/AvailabilityClient";
import { calculateTicketValidityDays } from "@/utils/bookingData";
import moment from "moment-timezone";
import { moduleBookingAdd } from "@/store/modules/moduleBookingAdd";
import { constructRateName, constructRateSalesWindow, constructRateUsageWindow, constructRateValidWindow } from "@/utils/rateutils";
import { formatYMD } from "@/filters/date";
import { profileModule } from "@/store/modules/moduleProfile";
import { getOrDefaultRetailAmount, getOrDefaultRetailCurrency, pricesHaveRetailTaxes, sortPricesByRetailAmount } from "@/api/rate/price.utils";
import { Price } from "@/api/rate/price.model";

const xsrfCookieName = "XSRF-TOKEN";
const rateListApi = "/rate-list";
const priceScheduleApi = "/price-schedule";

export class RateClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.RatesApiUrl,
      timeout: parseInt(config.ApiTimeout),
      withCredentials: true,
      xsrfCookieName,
      xsrfHeaderName: "X-CSRF-Token",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(RateClient.ErrorResponse));
  }

  private static ErrorResponse(err: AxiosErrorWithResponse) {
    if (err.response?.status === 401) {
      return RateClient.on401(err);
    }

    if (err.response.status >= 400 && err.response.status < 500) {
      const wpsErr = err.response.data;
      return Promise.reject({
        status: err.response.status,
        wpsErr,
        ...err,
      });
    }

    // not 4xx
    return Promise.reject({
      status: err.response.status,
      ...err,
    });
  }

  /* configure client */

  private static on401(err: any): Promise<any> {
    return Promise.reject(err);
  }

  private getRatesPricingRS(params: any, errs?: RateErrHandlers): Observable<any> {
    return from(this.httpClient.get<any>(`${rateListApi}` + "/" + params.supplierId + "/" + params.productId + "/rates")).pipe(
      switchMap((rs: any) => {
        const priceSC: Observable<any>[] = [];

        rs.data?.rates?.forEach(async (rate: any) => {
          params.rateId = rate.id;

          const ps: Observable<any> = this.getPriceSchedule(params).pipe(
            map((psData: any) => {
              return {
                ...rate,
                priceData: psData,
              };
            }),
            catchError((err) => {
              return of({
                ...rate,
                priceData: err,
              });
            }),
          );

          priceSC.push(ps);
        });

        return forkJoin(priceSC);
      }),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onRateNotFound && e.status === 404) {
            errs.onRateNotFound(e);
          }
        }, errs),
      ),
    );
  }

  private getRatesAllRS(params: any, errs?: RateErrHandlers): Observable<any> {
    return from(this.httpClient.get<any>(`${rateListApi}` + "/" + params.supplierId + "/" + params.productId + "/rates")).pipe(
      map((rs: any) => {
        return rs?.data;
      }),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onRateNotFound && e.status === 404) {
            errs.onRateNotFound(e);
          }
        }, errs),
      ),
    );
  }

  private getRateListBySupplierProduct(params: any, errs?: RateErrHandlers): Observable<any> {
    return from(this.httpClient.get<any>(`${rateListApi}` + "/" + params.supplierId + "/" + params.productId + "/rates")).pipe(
      switchMap(async (rs) => {
        const rates = rs.data?.rates;
        const timezone = extractTimezoneFromRates(rates);
        const dt = moment.tz(params.startDate, "YYYY-MM-DD", timezone);
        const startDate = dt.format();
        const endDate = dt.add(1, "day").subtract(1, "second").format();

        const availabilityRS: Observable<any> = availabilityClient.availabilityListBySupplierProduct({
          supplierId: params.supplierId,
          productId: params.productId,
          startDate: startDate,
          endDate: endDate,
        });

        params.rateId = "all";
        const priceDataRS: Observable<any> = this.getPriceSchedule(params);

        if (rs.data?.rates && Array.isArray(rs.data?.rates)) {
          let availData: any = null;
          let pricingData: any = null;
          await availabilityRS.toPromise().then((response: any) => (availData = response));
          await priceDataRS.toPromise().then((response: any) => (pricingData = response));
          rs.data?.rates.forEach((rate: any) => {
            rate.avData = availData?.availabilities?.byRate?.[rate.id]?.availability || [];
            rate.priceData = pricingData?.[rate.id] || {};
          });
        }
        return rates;
      }),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onRateNotFound && e.status === 404) {
            errs.onRateNotFound(e);
          }
        }, errs),
      ),
    );
  }

  private getMonthRateListBySupplierProduct(params: any, errs?: RateErrHandlers): Observable<any> {
    return from(this.httpClient.get<any>(`${rateListApi}` + "/" + params.supplierId + "/" + params.productId + "/rates")).pipe(
      switchMap(async (rs) => {
        const rates = rs.data?.rates;
        const timezone = extractTimezoneFromRates(rates);
        const dt = moment.tz(params.startDate, "YYYY-MM-DD", timezone);

        const startMonthDt = dt.startOf("month").format();
        const endMonthDt = dt.endOf("month").format();
        const startMonth = dt.startOf("month").format("YYYY-MM-DD");
        const endMonth = dt.endOf("month").format("YYYY-MM-DD");

        const availabilityRS: Observable<any> = availabilityClient.availabilityListBySupplierProduct({
          supplierId: params.supplierId,
          productId: params.productId,
          startDate: startMonthDt,
          endDate: endMonthDt,
        });

        params.rateId = "all";
        params.startDate = startMonth;
        params.endDate = endMonth;
        const priceDataRS: Observable<any> = this.getPriceSchedule(params);

        if (rs.data?.rates && Array.isArray(rs.data?.rates)) {
          let availData: any = null;
          let pricingData: any = null;
          await availabilityRS.toPromise().then((response: any) => (availData = response));
          await priceDataRS.toPromise().then((response: any) => (pricingData = response));
          rs.data?.rates.forEach((rate: any) => {
            rate.avData = availData?.availabilities?.byRate?.[rate.id]?.availability || [];
            rate.priceData = pricingData?.[rate.id] || {};
          });
        }

        return rates;
      }),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onRateNotFound && e.status === 404) {
            errs.onRateNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public async getRatesList(params: any): Promise<any> {
    const rateLS2 = await this.getRateListBySupplierProduct(params).toPromise();
    if (rateLS2?.length) {
      return this.mapRateLSRS(rateLS2, params);
    } else {
      return [];
    }
  }

  public async getRatesListByMonth(params: any): Promise<any> {
    const rateLS2 = await this.getMonthRateListBySupplierProduct(params).toPromise();
    if (rateLS2?.length) {
      return this.mapRateLSRS(rateLS2, params);
    } else {
      return rateLS2;
    }
  }

  private mapRateLSRS(rateLS: any, params: any) {
    let sortedRates = sortAlpha(rateLS || [], "name");

    sortedRates = sortedRates.map((rate: any) => {
      const ratesPriceSchedule = rate?.priceData;
      let prices: Price[] = [];
      if (!_.isEmpty(ratesPriceSchedule)) {
        prices = ratesPriceSchedule[params.startDate] || [];
      } else {
        prices = rate.prices;
      }

      // sort it by retail amount, find the high and low prices
      prices = sortPricesByRetailAmount(prices);
      let highestPrice = getOrDefaultRetailAmount(prices[0]);
      const lowestPrice = getOrDefaultRetailAmount(prices[0]);
      if (prices.length > 1) {
        highestPrice = getOrDefaultRetailAmount(prices[prices.length - 1]);
      }
      const currency = getOrDefaultRetailCurrency(lowestPrice);
      const prettyHigh = FromSmallestUnit(highestPrice.amount || 0, getOrDefaultRetailCurrency(highestPrice));
      const prettyLow = FromSmallestUnit(lowestPrice.amount || 0, getOrDefaultRetailCurrency(lowestPrice));

      // taxes
      const hasTaxes = pricesHaveRetailTaxes(prices);
      // FIXME: I don't understand this, but it was here before; leaving it for now
      const taxes = hasTaxes ? prices : null;

      // construct price range text
      const priceRange = `Prices range from ${prettyLow} to ${prettyHigh} ${currency} ${taxes !== null ? `(tax included)` : ""}`;

      // construct additional details
      const additionalDetails = [priceRange];
      // append the valid, usage and sales windows to the additional details if we have them
      const validWindow = constructRateValidWindow(rate);
      if (validWindow != "") {
        additionalDetails.push(validWindow);
      }
      const salesWindow = constructRateSalesWindow(rate);
      if (salesWindow != "") {
        additionalDetails.push(salesWindow);
      }
      const usageWindow = constructRateUsageWindow(rate);
      if (usageWindow != "") {
        additionalDetails.push(usageWindow);
      }
      if (profileModule.IsMilitary) {
        additionalDetails.push("Reservation required");
      }

      // construct card text
      const cardText = `${priceRange} ${validWindow}`;

      const bufferDays = parseInt(rate?.ext?.["beta-bookingBufferDays"]);
      const productDuration = rate?.ext?.["beta-productDuration"] ? rate?.ext?.["beta-productDuration"] : "";

      rate["policy"] = rate?.ext?.["beta-policy"] || "";
      const marketRegions = rate?.ext?.["beta-marketRegions"] ? [getMarketRegionName(rate?.ext?.["beta-marketRegions"])] : [];
      const discountGroups = rate?.ext?.["beta-discountGroups"] ? [getDiscountGroupName(rate?.ext?.["beta-discountGroups"])] : [];

      const priceScheduleData = prices.map((item: any) => {
        let ageBands = "";
        const trMinAge = item?.travelerType?.minAge || 0;
        const trMaxAge = item?.travelerType?.maxAge;

        if (trMinAge && trMaxAge) {
          ageBands = `${trMinAge} - ${trMaxAge} years`;
        }

        if (trMaxAge && parseInt(trMaxAge) > 100) {
          ageBands = trMinAge + "+ years";
        }

        const calcData = calculateTicketValidityDays(productDuration, bufferDays, params.startDate, false, null, null);

        const validityRange = calculateValidityRange(rate?.ext, moduleBookingAdd.SelectedDate);

        return {
          ...item,
          type: item.travelerType.ageBand,
          ageBand: ageBands,
          pricePerTicket: item?.retail?.amount,
          validityRange: moduleBookingAdd.IsDisney
            ? `${validityRange.startDate} - ${validityRange.endDate}`
            : `${calcData.timeStartDate} - ${calcData.timeEndDate}`,
          currency: item?.retail?.currency || item?.net?.currency,
          startTimes: item?.startTimes,
        };
      });

      rate["priceSchedule"] = sortAlpha(priceScheduleData || [], "type");
      const rateName = constructRateName(rate);

      return {
        ...rate,
        keyName: rate.id,
        value: rate.id,
        cardTitle: rateName,
        cardText: cardText,
        radioName: "radioCard",
        isSelected: false,
        retailPrice: lowestPrice,
        additionalDetails: additionalDetails,
        marketRegions: marketRegions,
        discountGroups: discountGroups,
        validUntil: formatYMD(rate.valid?.until),
        tax: taxes,
      };
    });
    return sortAlpha(sortedRates, "cardTitle");
  }

  public async getRatesWithPricing(params: any): Promise<any> {
    const ratesWithPricing = await this.getRatesPricingRS(params).toPromise();
    if (ratesWithPricing?.length) {
      return this.mapRateLSRS(ratesWithPricing, params);
    } else {
      return ratesWithPricing;
    }
  }

  public async getRatesAll(params: any): Promise<any> {
    const ratesAll = await this.getRatesAllRS(params).toPromise();

    if (ratesAll?.rates?.length) {
      const mapRateWithMR = ratesAll?.rates.map((rate: any) => {
        const rateName = constructRateName(rate);
        return {
          ...rate,
          rateName,
        };
      });

      return sortAlpha(mapRateWithMR, "rateName");
    } else {
      return ratesAll;
    }
  }

  private getPriceSchedule(params: any, errs?: RateErrHandlers): Observable<PriceScheduleRS> {
    const endDate = params.endDate ? params.endDate : moment(params.startDate).add(parseInt(config.PriceScheduleOffsetBooking), "days").format("YYYY-MM-DD");
    return from(
      this.httpClient.get<any>(`${priceScheduleApi}` + "/" + params.supplierId + "/" + params.productId + "/" + params.rateId, {
        params: {
          start_date: params.startDate ? params.startDate : "",
          end_date: params.startDate ? endDate : "",
        },
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }),
    ).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onRateNotFound && e.status === 404) {
            errs.onRateNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public async getRatesPriceSchedule(params: any): Promise<any> {
    return await this.getPriceSchedule(params).toPromise();
  }
}

const rateClient = new RateClient();

export default rateClient;
