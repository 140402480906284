import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import suppliersClient from "../../api/suppliers/SuppliersClient";
import { appModule } from "@/store/modules/moduleApp";

@Module({
  dynamic: true,
  store,
  name: "rdm-rc-supplier-details",
  namespaced: true,
})
class ModuleSuppliers extends VuexModule {
  /* Data */
  private loading = false;
  private supplierDetails = [];
  private supplierID = "";
  private suppliersList: any[] = [];

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get SupplierID(): string {
    return this.supplierID;
  }

  get SuppliersList() {
    return this.suppliersList;
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setSupplierDetails(details: []) {
    this.supplierDetails = details;
  }

  @Mutation
  setSuppliersList(suppliersList: any) {
    this.suppliersList = suppliersList;
  }

  /* Actions */
  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  async getSupplierDetails() {
    await Promise.resolve(this.setLoading(true))
      .then(() => suppliersClient.getSupplierDetails(this.SupplierID))
      .then((details) => this.setSupplierDetails(details))
      .finally(() => this.setLoading(false));
  }

  @Action
  async getSuppliersList() {
    await Promise.resolve(this.setLoading(true))
      .then(() => suppliersClient.getSuppliers())
      .then((suppliers) => {
        suppliers.sort(function (a: any, b: any) {
          const start = a.text.toLowerCase().charCodeAt();
          const end = b.text.toLowerCase().charCodeAt();
          return start - end;
        });
        this.setSuppliersList(suppliers);
      })
      .catch((error) => {
        const errMsg = error?.response?.data?.error?.message ? `Supplier Error: ${error?.response?.data?.error?.message}` : "Suppliers Load Error";
        appModule.addErrorMsg(errMsg);
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
  }
}

export const moduleSuppliers = getModule(ModuleSuppliers, store);
