/** Beta Extensions */
export const EXT_BETA_TICKET_LOCATIONS = "beta-ticketLocations";
export const EXT_BETA_USAGE_START = "beta-usageStart";
export const EXT_BETA_USAGE_END = "beta-usageEnd";

/** Disney Specific Extensions */
export const EXT_DISNEY_SALES_START = "disney-salesStart";
export const EXT_DISNEY_SALES_END = "disney-salesEnd";
export const EXT_DISNEY_PARK_RESERVATIONS_REQUIRED = "disney-parkReservationRequired";
export const EXT_DISNEY_BULK_TICKET_PURCHASE = "disney.bulkTicketPurchase";
export const EXT_DISNEY_BULK_TICKET_DELIVERY_DATE = "disney.deliveryDate";
export const EXT_DISNEY_BULK_TICKET_DELIVERY_INSTRUCTIONS = "disney.deliveryInstructions";
export const EXT_DISNEY_BULK_TICKET_DELIVERY_TYPE = "disney.deliveryType";

/** Reseller Portal Specific Extensions */
export const EXT_RESELLER_PORTAL_AGENT_NAME = "x-resellerPortal.AgentName";
export const EXT_RESELLER_PORTAL_AGENT_EMAIL = "x-resellerPortal.AgentEmail";
export const EXT_RESELLER_PORTAL_RESELLER_NAME = "x-resellerPortal.ResellerName";
export const EXT_RESELLER_PORTAL_RESELLER_REGION = "x-resellerPortal.ResellerRegion";
