import moment from "moment-timezone";

// Discount Groups with readable names
export const ReadableDiscountGroups = [
  {
    name: "CANADA_RESIDENT",
    value: "Canada Resident",
  },
  {
    name: "NET_OFFER",
    value: "Central Florida Post Arrival",
  },
  {
    name: "CONVENTION",
    value: "Convention Delegates",
  },
  {
    name: "FL_RESIDENT",
    value: "Florida Resident",
  },
  {
    name: "STD_GST",
    value: "Standard Theme Park Tickets",
  },
  {
    name: "TIMESHARE_TOUR",
    value: "Timeshare Tour Sales",
  },
  {
    name: "H2OGLOW",
    value: "Disney H2O Glow",
  },
  {
    name: "ICU_WORLDS",
    value: "International Cheer Union World Cheerleading Championships and USASF and IASF Cheerleading and Dance Worlds",
  },
  {
    name: "D2",
    value: "The D2 Summit – All Star Championship",
  },
  {
    name: "QUEST_IASC",
    value: "The Quest Recreational Championship and UCA International All Star Championship",
  },
  {
    name: "SUMMIT",
    value: "The Summit – All Star Championship",
  },
  {
    name: "NHSCC",
    value: "UCA National High School Cheerleading Championship",
  },
  {
    name: "COLLEGE",
    value: "UCA/UDA College Cheerleading and Dance Team National Championship",
  },
  {
    name: "NDTC",
    value: "UDA National Dance Team Championship",
  },
  {
    name: "OTT",
    value: "Varsity Spirit Orlando Thanksgiving Tour",
  },
  {
    name: "VSS",
    value: "Varsity Spirit Spectacular",
  },
];

//Market Regions with readable names
export const ReadableMarketRegions = [
  { name: "APAC_OTA", value: "Asia Pacific Direct to Guest" },
  { name: "CAN_WHOLESALE", value: "Canada Wholesale" },
  { name: "CH_IN_OTA", value: "China/India Direct to Guest" },
  { name: "DEST_SALES", value: "Destination Sales" },
  { name: "DOM_META", value: "Domestic Metasearch" },
  { name: "DOM_WHOLESALE", value: "Domestic Wholesale" },
  { name: "INTL_WHOLESALE", value: "International Wholesale" },
  { name: "LATIN_OTA", value: "Latin America Direct to Guest" },
  { name: "LATAM_WHOLESALE", value: "Latin America Wholesale" },
  { name: "NAT_SALES", value: "National Sales" },
  { name: "UK_NONSELECT", value: "UK Not Qualified" },
  { name: "UK_SELECT", value: "UK Qualified" },
  { name: "WDTC_UK", value: "WDTC UK" },
  { name: "ASIA_TICKETONLY", value: "Asia Ticket Only" },
  { name: "COLLEGE_DAYS", value: "Disney College Days" },
  { name: "VARSITY", value: "Varsity Events" },
];

export function calculateTicketValidityDays(productDuration: any, bufferDays: number, startDate: string, isWillCall: boolean, supplier: any, booking: any) {
  let los = parseInt(productDuration);
  los = isNaN(los) ? 1 : los;

  const isDisney = supplier && supplier?.ext?.["x-ticketingSystem"] === "Disney";
  if (isDisney) {
    const tsd = moment(booking?.ext?.["beta-ticketStartDate"]);
    const ted = moment(booking?.ext?.["beta-ticketEndDate"]);
    return {
      ticketValidityDays: los,
      timeStartDate: isWillCall ? tsd.format("MM/DD/YYYY") : tsd.format("YYYY-MM-DD"),
      timeEndDate: isWillCall ? ted.format("MM/DD/YYYY") : ted.format("YYYY-MM-DD"),
    };
  }

  bufferDays = isNaN(bufferDays) ? 0 : bufferDays;
  const timeStartDate = isWillCall ? moment(startDate).format("MM/DD/YYYY") : startDate;
  const ticketValidityDays = los; // previously was const ticketValidityDays = los + bufferDays - 1;
  let timeEndDate: string | undefined = "";
  if (ticketValidityDays < 1) {
    timeEndDate = timeStartDate;
  } else {
    if (!isWillCall) {
      timeEndDate = moment(startDate).add(ticketValidityDays, "days").format("YYYY-MM-DD") || "";
    } else {
      timeEndDate = moment(startDate).add(ticketValidityDays, "days").format("MM/DD/YYYY") || "";
    }
  }
  return {
    ticketValidityDays,
    timeStartDate,
    timeEndDate,
  };
}
