import { isDefinedAndNotEmpty } from "@/utils/stringutils";
import { EXT_BETA_USAGE_END, EXT_BETA_USAGE_START, EXT_DISNEY_SALES_END, EXT_DISNEY_SALES_START } from "@/api/booking/Extensions";
import { YYYYMMDDFormat } from "@/filters/date";
import moment from "moment-timezone";

/**
 * constructRateName - set rate name to the rate title if present, if not use the rate name
 * @param rate
 */
export function constructRateName(rate: any): string {
  return isDefinedAndNotEmpty(rate?.title) ? rate?.title : rate?.name || "";
}

/**
 * constructRateValidWindow - builds the rate valid window
 * @param rate
 */
export function constructRateValidWindow(rate: any): string {
  const start = rate.valid?.from || "";
  const end = rate.valid?.until || "";
  if (isDefinedAndNotEmpty(start) && isDefinedAndNotEmpty(end)) {
    const localTimezone = rate?.hours[0]?.timezone || "UTC";
    const localizedStart = moment(start).tz(localTimezone).format(YYYYMMDDFormat);
    const localizedEnd = moment(end).tz(localTimezone).format(YYYYMMDDFormat);
    return `This ticket is valid between ${localizedStart} and ${localizedEnd}`;
  }
  return "";
}

/**
 * constructRateSalesWindow - if the sales range is in the extension map constructs the sales window text
 * @param rate
 */
export function constructRateSalesWindow(rate: any): string {
  const start = rate?.ext?.[EXT_DISNEY_SALES_START] || "";
  const end = rate?.ext?.[EXT_DISNEY_SALES_END] || "";
  if (isDefinedAndNotEmpty(start) && isDefinedAndNotEmpty(end)) {
    const localTimezone = rate?.hours[0]?.timezone || "UTC";
    const localizedStart = moment(start).tz(localTimezone).format(YYYYMMDDFormat);
    const localizedEnd = moment(end).tz(localTimezone).format(YYYYMMDDFormat);
    return `This ticket can be sold between ${localizedStart} and ${localizedEnd}`;
  }
  return "";
}

/**
 * constructRateUsageWindow - if the usage range is in the extension map constructs the usage window text
 * @param rate
 */
export function constructRateUsageWindow(rate: any): string {
  const start = rate?.ext?.[EXT_BETA_USAGE_START] || "";
  const end = rate?.ext?.[EXT_BETA_USAGE_END] || "";
  if (isDefinedAndNotEmpty(start) && isDefinedAndNotEmpty(end)) {
    const localTimezone = rate?.hours[0]?.timezone || "UTC";
    const localizedStart = moment(start).tz(localTimezone).format(YYYYMMDDFormat);
    const localizedEnd = moment(end).tz(localTimezone).format(YYYYMMDDFormat);
    return `This ticket can be used between ${localizedStart} and ${localizedEnd}`;
  }
  return "";
}
