import { isArrayNotEmpty } from "@/utils/arrayutils";
import { Price, Retail } from "@/api/rate/price.model";

/**
 * hasRetailPrices checks to see if there are any retail prices
 * @param prices
 */
export function hasRetailPrices(prices: Price[]): boolean {
  if (!isArrayNotEmpty(prices)) {
    return false;
  }
  const retailPrices = prices.filter((price) => {
    return price.retail && price.retail.amount;
  });
  return isArrayNotEmpty(retailPrices);
}

/**
 * pricesHaveRetailTaxes for the prices checks to see if there are any retail prices
 * @param prices
 */
export function pricesHaveRetailTaxes(prices: Price[]): boolean {
  if (!isArrayNotEmpty(prices)) {
    return false;
  }
  const pricesWithRetailTax = prices.filter((price) => {
    if (!isArrayNotEmpty(price.includedTaxes)) {
      return false;
    }
    const retailTaxes = price.includedTaxes?.filter((tax) => {
      if (tax.retail && tax.retail > 0) {
        return true;
      }
    });
    return retailTaxes && retailTaxes.length > 0;
  });
  return isArrayNotEmpty(pricesWithRetailTax);
}

/**
 * sortPricesByRetailAmount sorts the prices by retail amount if it exists
 * @param prices
 */
export function sortPricesByRetailAmount(prices: Price[]): Price[] {
  if (!isArrayNotEmpty(prices)) {
    return prices;
  }
  return prices?.sort(function (a, b) {
    if (a.retail && b.retail) {
      return (a.retail?.amount || 0) - (b.retail?.amount || 0);
    }
    return 0;
  });
}

/**
 * getOrDefaultCurrency returns the currency or defaults to USD
 * @param retail
 */
export function getOrDefaultRetailCurrency(retail: Retail): string {
  if (retail && retail.currency) {
    return retail.currency;
  }
  return "USD";
}

/**
 * getOrDefaultRetailAmount returns the retail amount or default
 * @param price
 */
export function getOrDefaultRetailAmount(price: Price): Retail {
  if (price && price.retail && price.retail.amount) {
    return price.retail;
  }
  return {
    amount: 0,
    currency: "USD",
  };
}
