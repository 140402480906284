import axios, { AxiosInstance } from "axios";
import { from, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { config } from "@/utils/config";
import { AxiosErrorWithResponse, intercept } from "@/api/AxiosInterceptors";
import { handleErrs } from "@/api/ErrHandlers";
import { SupplierDetailsRS, SuppliersListRS } from "./SupplierModels";
import { SuppliersErrHandlers } from "./ErrHandlers";

const xsrfCookieName = "XSRF-TOKEN";

export class SuppliersClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.SuppliersApiUrl,
      timeout: parseInt(config.ApiTimeout),
      withCredentials: true,
      xsrfCookieName,
      xsrfHeaderName: "X-CSRF-Token",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(SuppliersClient.ErrorResponse));
  }

  private static ErrorResponse(err: AxiosErrorWithResponse) {
    if (err.response?.status === 401) {
      return SuppliersClient.on401(err);
    }

    if (err.response.status >= 400 && err.response.status < 500) {
      const wpsErr = err.response.data;
      return Promise.reject({
        status: err.response.status,
        wpsErr,
        ...err,
      });
    }

    // not 4xx
    return Promise.reject({
      status: err.response.status,
      ...err,
    });
  }

  /* configure client */

  private static on401(err: any): Promise<any> {
    return Promise.reject(err);
  }

  public handle401(on401: (err: any) => void) {
    SuppliersClient.on401 = (err: any) => {
      on401(err);
      return Promise.reject(err);
    };
  }

  private suppliersDetails(supplierId: string, errs?: SuppliersErrHandlers): Observable<SupplierDetailsRS> {
    return from(this.httpClient.get<any>(`/${supplierId}`)).pipe(
      map((rs) => rs.data),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onSupplierNotFound && e.status === 404) {
            errs.onSupplierNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public async getSupplierDetails(supplierId: string): Promise<any> {
    return this.suppliersDetails(supplierId)
      .toPromise()
      .then((suppliersInfo) => (suppliersInfo?.supplier ? suppliersInfo?.supplier : suppliersInfo));
  }

  public getSuppliers(): Promise<any> {
    return from(this.httpClient.get<SuppliersListRS>(""))
      .pipe(
        map((res) =>
          res.data?.suppliers?.map((item) => {
            return { text: item.name, value: item.id, ext: item?.ext };
          }),
        ),
      )
      .toPromise();
  }
}

const suppliersClient = new SuppliersClient();

export default suppliersClient;
